<template>
  <main class="p-3 px-4 container">
    <input-field
      class="mb-5 mt-2 wd-100p wd-md-80p ms-3"
      label="Project Name"
      v-model="name"
    />
    <div class="text-muted-light strong-header-text font-weight-300 mb-4 ms-3">
      Residence Type
    </div>
    <div class="create-page__residence-container mb-5 ms-3">
      <select-question
        v-if="question"
        :options="question.answer_options"
        v-model="question.answer_id"
      />
    </div>
    <div class="text-muted-light strong-header-text font-weight-300 mb-2 ms-3">
      Is your residence part of a compound
    </div>
    <div class="d-flex align-items-center ms-3">
      <v-radio-group v-model="compound" row>
        <v-radio
          v-for="option in ['Yes', 'No']"
          color="#fff"
          class="me-5"
          dark
          :key="option"
          :value="option"
        >
          <template #label>
            <span class="ms-1 text-muted-light body-text">{{ option }}</span>
          </template>
        </v-radio>
      </v-radio-group>
    </div>
  </main>
</template>

<script>
import InputField from "../../components/InputField.vue";
import SelectQuestion from "../../components/SelectQuestion.vue";

export default {
  name: "Create",
  components: {
    InputField,
    SelectQuestion,
  },
  data() {
    return {
      name: this.$store.state.project.name,
      compound: null,
      selectedResidence: null,
      question: null,
    };
  },
  mounted() {
    this.$emit("showWelcomeModal");
    this.$store.dispatch("project/getSystems").then(() => {
      this.$store.dispatch("dashboard/initializeProjectRoom").then(() => {
        this.question = this.getQuestion();
      });
    });
  },
  methods: {
    getQuestion() {
      let question = this.$store.state.dashboard.rooms
        .find((r) => r.name === "Project")
        .systems.find((s) => s.category === "network").questions[0];

      question.answer_options = question.answer_options.map((option) => {
        return {
          ...option,
          label: option.text,
          image: require(`../../assets/icons/residence-types/${option.text.replaceAll(
            " ",
            "-"
          )}.png`),
        };
      });

      return question;
    },
  },
  watch: {
    name: function (val) {
      this.$store.commit("project/updateName", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0 !important;
}

.create-page {
  &__residence-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    row-gap: 2rem;

    width: 80%;
    @media (max-width: 700px) {
      width: 100%;
    }
  }

  ::v-deep &__radio {
    width: 2em;
    height: 2em;
  }
}
</style>
